.cmp-responsive-image img,
.cmp-responsive-bg-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.cmp-responsive-image .broken-image,
.cmp-responsive-bg-image .broken-image {
  display: none;
}

.cmp-responsive-image.replaced img,
.cmp-responsive-bg-image.replaced img {
  position: absolute;
}

/* Parallax */
.cmp-responsive-bg-image.parallax {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0;
}

.cmp-responsive-bg-image.parallax--active {
  opacity: 1;
}

.cmp-responsive-bg-image--eager.parallax.parallax--scroll_mask,
.cmp-responsive-bg-image--lazy.parallax.parallax--scroll_mask.replaced {
  width: 100%;
  min-height: 30vh;
  max-height: 66.66vh;
  overflow: hidden;
  background-size: auto 100vh;
  background-attachment: fixed;
  opacity: 1;
}

/* stylelint-disable max-nesting-depth */
/* This is a browser-specific target for Safari versions 10 and up */
@media not all and (min-resolution: 0.001dpcm) {
  .cmp-responsive-bg-image--eager.parallax.parallax--scroll_mask,
  .cmp-responsive-bg-image--lazy.parallax.parallax--scroll_mask.replaced {
    background-size: cover;
    background-attachment: initial;
  }
}
/* stylelint-enable max-nesting-depth */

.cmp-responsive-bg-image--eager.parallax.parallax--scroll_mask::before,
.cmp-responsive-bg-image--lazy.parallax.parallax--scroll_mask.replaced::before {
  content: '';
  width: var(--spacing-xxxs);
  margin-left: calc(var(--spacing-xxxs) * -1);
  float: left;
  height: 0;
  padding-top: 56.25%;
}

.cmp-responsive-bg-image--eager.parallax.parallax--scroll_mask::after,
.cmp-responsive-bg-image--lazy.parallax.parallax--scroll_mask.replaced::after {
  content: '';
  display: table;
  clear: both;
}

.cmp-responsive-bg-image--eager.parallax.parallax--image_zoom,
.cmp-responsive-bg-image--lazy.parallax.parallax--image_zoom.replaced {
  width: 100%;
  padding-top: 56.5%;
  background-size: cover;
}

.cmp-responsive-bg-image--eager.parallax.parallax--vertical_scrolling,
.cmp-responsive-bg-image--lazy.parallax.parallax--vertical_scrolling.replaced {
  background-position: center 0;
  background-size: auto 120%;
  width: 100%;
  overflow: hidden;
}

.cmp-responsive-bg-image--eager.parallax.parallax--vertical_scrolling::before,
.cmp-responsive-bg-image--lazy.parallax.parallax--vertical_scrolling.replaced::before {
  content: '';
  width: var(--spacing-xxxs);
  margin-left: calc(var(--spacing-xxxs) * -1);
  float: left;
  height: 0;
  padding-top: 56.25%;
}

.cmp-responsive-bg-image--eager.parallax.parallax--vertical_scrolling::after,
.cmp-responsive-bg-image--lazy.parallax.parallax--vertical_scrolling.replaced::after {
  content: '';
  display: table;
  clear: both;
}

.cmp-stage-page-intro__meta {
  margin-bottom: var(--spacing-sm);
}

.cmp-stage-page-intro__meta-item {
  margin-bottom: var(--spacing-sm);
  display: flex;
  align-items: flex-start;
}

.cmp-stage-page-intro__meta-item:last-child {
  margin-bottom: 0;
}

.cmp-stage-page-intro__meta-item__icon {
  display: inline-flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  vertical-align: middle;
}

.cmp-stage-page-intro__meta-item__text {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  line-height: var(--line-height-md);
}

.cmp-stage-page-intro__meta-item__text [href] {
  display: inline-block;
  text-decoration: underline;
}

.cmp-stage-page-intro__meta-item__icon + .cmp-stage-page-intro__meta-item__text {
  margin-left: var(--spacing-xs);
}

.cmp-stage-page-intro__divider {
  border: 0;
  border-top: var(--spacing-xxxs) solid var(--color-gray-100);
  margin: var(--spacing-sm) 0 var(--spacing-lg);
}

.cmp-mddpromotion {
  width: 100%;
  height: 100%;
  display: flex;
  gap: var(--spacing-lg);
  animation: navigation-mdd-promotion-up 0.4s ease-in-out 0.4s 1 forwards;
}

.cmp-mddpromotion__wrapper {
  height: 100%;
}

.cmp-mddpromotion__full-link {
  height: 100%;
  width: 100%;
}

.cmp-mddpromotion--horizontalTeasers {
  display: flex;
  flex-flow: column;
}

.cmp-mddpromotion__placeholder {
  overflow: hidden;
  position: relative;
  box-shadow: 0 0.063rem 0.188rem rgb(0 0 0 / 40%);
}

.cmp-mddpromotion__placeholder:hover {
  box-shadow: 0 0.125rem 0.75rem -0.125rem rgb(0 0 0 / 40%);
}

.cmp-mddpromotion--verticalTeaser .cmp-mddpromotion__placeholder,
.cmp-mddpromotion--verticalTeasers .cmp-mddpromotion__placeholder,
.cmp-mddpromotion--verticalLinkList .cmp-mddpromotion__placeholder,
.cmp-mddpromotion--verticalTeaserAndLinkList .cmp-mddpromotion__placeholder,
.cmp-mddpromotion--horizontalAndVerticalTeasers .cmp-mddpromotion__wrapper--vertical .cmp-mddpromotion__placeholder,
.cmp-mddpromotion--horizontalTeasersAndLinkList .cmp-mddpromotion__placeholder.cmp-mddpromotion__placeholder--vertical {
  height: 22.5rem;
  width: 17.625rem;
}

.cmp-mddpromotion--horizontalTeasersAndLinkList .cmp-mddpromotion__placeholder.cmp-mddpromotion__placeholder--vertical {
  grid-area: vertical;
}

.cmp-mddpromotion--horizontalTeaser .cmp-mddpromotion__placeholder,
.cmp-mddpromotion--horizontalTeasers .cmp-mddpromotion__placeholder,
.cmp-mddpromotion--horizontalAndVerticalTeasers .cmp-mddpromotion__placeholder,
.cmp-mddpromotion--horizontalTeasersAndLinkList .cmp-mddpromotion__placeholder {
  height: 10.5rem;
  width: 17.625rem;
}

.cmp-mddpromotion--horizontalAndVerticalTeasers,
.cmp-mddpromotion--horizontalTeasersAndLinkList {
  display: grid;
  grid-auto-columns: min-content;
  grid-template-areas: 'horizontal1 vertical' 'horizontal2 vertical';
}

.cmp-mddpromotion--horizontalAndVerticalTeasers .cmp-mddpromotion__wrapper:not(.cmp-mddpromotion__wrapper--vertical):first-child,
.cmp-mddpromotion--horizontalTeasersAndLinkList .cmp-mddpromotion__wrapper:not(.cmp-mddpromotion__wrapper--vertical):first-child {
  grid-area: horizontal1;
}

.cmp-mddpromotion--horizontalAndVerticalTeasers .cmp-mddpromotion__wrapper:not(.cmp-mddpromotion__wrapper--vertical):nth-child(2),
.cmp-mddpromotion--horizontalTeasersAndLinkList .cmp-mddpromotion__wrapper:not(.cmp-mddpromotion__wrapper--vertical):nth-child(2) {
  grid-area: horizontal2;
}

.cmp-mddpromotion--horizontalAndVerticalTeasers .cmp-mddpromotion__wrapper.cmp-mddpromotion__wrapper--vertical,
.cmp-mddpromotion--horizontalTeasersAndLinkList .cmp-mddpromotion__wrapper.cmp-mddpromotion__wrapper--vertical {
  grid-area: vertical;
}

.cmp-mddpromotion__placeholder-content.darkGrey {
  background-color: var(--color-gray-900);
}

.cmp-mddpromotion__placeholder-content.red {
  background-color: var(--color-primary-500);
}

.cmp-mddpromotion__placeholder-content {
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  padding: var(--spacing-lg);
  height: 100%;
  min-height: 8.75rem;
  justify-content: flex-end;
  text-align: left;
}

.cmp-mddpromotion__title {
  @include bold-xl();

  z-index: 1;
  margin-bottom: var(--spacing-sm);
}

.cmp-mddpromotion__description,
.cmp-mddpromotion__linklist-link {
  @include regular-md();

  z-index: 1;
  margin-bottom: var(--spacing-sm);
  display: flex;
}

.cmp-mddpromotion__linklist-link:hover .cmp-mddpromotion__link-text {
  text-decoration: underline;
}

.cmp-mddpromotion__linklist-link-wrapper {
  max-height: 80%;
  z-index: 1;
  overflow: hidden;
}

.cmp-mddpromotion__link {
  font-weight: var(--font-weight-bold);
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:hover {
    color: var(--color-white);

    .cmp-mddpromotion__link-text {
      text-decoration: underline;
    }
  }
}

.cmp-mddpromotion__placeholder-image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.cmp-mddpromotion__placeholder-image::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(0deg, rgb(0 0 0 / 35%), rgb(0 0 0 / 35%));
}

.cmp-mddpromotion__placeholder .cmp-mddpromotion__wrapper .cmp-mddpromotion__placeholder-image::after {
  background: linear-gradient(0deg, rgb(0 0 0 / 60%), rgb(0 0 0 / 60%));
}

@media (--breakpoint-down-sm) {
  .cmp-mddpromotion {
    flex-flow: column;
  }

  .cmp-mddpromotion .cmp-mddpromotion__placeholder,
  .cmp-mddpromotion .cmp-mddpromotion__wrapper--vertical .cmp-mddpromotion__placeholder,
  .cmp-mddpromotion--horizontalTeasersAndLinkList .cmp-mddpromotion__placeholder.cmp-mddpromotion__placeholder--vertical {
    width: 100%;
    height: auto;
    max-height: 22.5rem;
    min-height: 8.75rem; /* 140px */
  }

  .cmp-mddpromotion__placeholder-content.image {
    margin-bottom: 0;
  }

  .cmp-mddpromotion--horizontalAndVerticalTeasers,
  .cmp-mddpromotion--horizontalTeasersAndLinkList {
    grid-auto-columns: auto;
    grid-template-areas: 'horizontal1' 'horizontal2' 'vertical';
  }
}

/* stylelint-disable selector-max-compound-selectors */

:root {
  --rebrand-large-page-stage-height: 32.125rem; /* 514 px */
  --rebrand-medium-page-stage-height: 25rem; /* 400 px */

  --rebrand-text-gradient:
    linear-gradient(
      to right top,
      #b63939,
      #9b2d30,
      #812127,
      #68161e,
      #500c15,
      #420912,
      #35070d,
      #290205,
      #220206,
      #1b0106,
      #100104,
      #000
    );

  --rebrand-text-gradient-light: linear-gradient(to right top, #b63939, #d49496, #fff, #fff, #fcfcfc, #fff, #fefefe, #fff);
}

.page-stage:has(.page-stagev2--rebrand) {
  @include add-content-padding();

  .cmp-anchor-navigation:not(.cmp-anchor-navigation--sticky)::before {
    content: '';
    display: block;
    margin-top: var(--spacing-xxxl);
  }
}

.page-stagev2--rebrand {
  margin: 0;

  @include extendBeyondContentWidth();

  .page-stagev2__wrapper {
    display: flex;
    flex-direction: column;

    &.page-stagev2__wrapper--large {
      @media (--breakpoint-up-sm) {
        height: var(--rebrand-large-page-stage-height);
      }
    }

    &.page-stagev2__wrapper--medium {
      @media (--breakpoint-up-sm) {
        height: var(--rebrand-medium-page-stage-height);
      }
    }

    &.page-stagev2__wrapper--crop {
      &-top {
        img {
          object-position: top;
        }

        @media (--breakpoint-up-sm) {
          .cmp-video-wrapper video {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      &-middle {
        img {
          object-position: center;
        }

        @media (--breakpoint-up-sm) {
          .cmp-video-wrapper video {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &-bottom {
        img {
          object-position: bottom;
        }

        @media (--breakpoint-up-sm) {
          .cmp-video-wrapper video {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    @media (--breakpoint-up-sm) {
      .cmp-video-wrapper video,
      .cmp-cta__image img {
        min-width: var(--max-full-width);
      }

      .cmp-cta__image img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    @media #{$mq-1} {
      .cmp-cta__image {
        aspect-ratio: 16/9;
        position: relative;

        img {
          width: 100%;
        }
      }

      .page-stagev2__video-root {
        aspect-ratio: 16/9;
        height: auto;

        video {
          position: relative;
          width: 100%;
        }
      }
    }

    &.page-stagev2__wrapper--text-only {
      height: auto;

      .cmp-banner-cta__content-inner {
        margin: 0;
      }
    }

    .cmp-banner-cta__content {
      @include fullWidth(var(--page-max-width));

      &-inner {
        margin: 0 0 var(--spacing-xxl);
        padding: toRem(21px) toRem(28px);
        backdrop-filter: blur(var(--spacing-md));
        border: var(--border-xs) solid var(--color-gray-300);
        border-radius: var(--border-radius-m);

        @media #{$mq-1} {
          margin-bottom: 0;
          padding: toRem(21px) toRem(28px);
          border-radius: 0;
        }

        .page-stagev2-inner {
          &__preline,
          &__preline p {
            @include bold-md();

            text-transform: none;
            padding: 0;
            margin: 0;
          }

          &__title {
            @include heading-1();

            padding: 0;
            margin: 0;
          }

          &__text,
          &__text p {
            @include regular-lg();

            padding: 0;
            margin: 0;
          }
        }

        .cmp-banner-cta {
          &__preline {
            margin: 0 0 var(--spacing-xs);
          }

          &__title {
            margin: 0 0 var(--spacing-lg);
          }

          &__text {
            margin: 0 0 var(--spacing-lg);
          }

          &__button {
            margin: 0;
          }
        }
      }

      &-dark,
      &-dark-wide {
        .cmp-banner-cta__content-inner {
          background-color: var(--color-black-op-60);
          color: var(--color-white);

          @media #{$mq-1} {
            background: var(--rebrand-text-gradient);
            border: none;
          }
        }
      }

      &-light,
      &-light-wide {
        .cmp-banner-cta__content-inner {
          background-color: var(--color-white-op-60);
          color: var(--color-gray-900);

          @media #{$mq-1} {
            background: var(--rebrand-text-gradient-light);
            border: none;
          }
        }
      }

      &-dark-wide,
      &-light-wide {
        max-width: toRem(650px);

        @media #{$mq-1} {
          max-width: unset;
        }
      }

      &-dark,
      &-light {
        max-width: toRem(446px);

        @media #{$mq-1} {
          max-width: unset;
        }
      }
    }

    &--text-only {
      .cmp-banner-cta__content {
        &-inner {
          padding: 0;
        }

        &-dark,
        &-dark-wide {
          .cmp-banner-cta__content-inner {
            background-color: unset;
            color: unset;
            border: 0;
          }
        }

        &-light,
        &-light-wide {
          .cmp-banner-cta__content-inner {
            background-color: unset;
            color: unset;
            border: 0;
          }
        }

        &-dark-wide,
        &-light-wide {
          max-width: toRem(650px);

          @media #{$mq-1} {
            max-width: unset;
          }
        }

        &-dark,
        &-light {
          max-width: toRem(446px);

          @media #{$mq-1} {
            max-width: unset;
          }
        }
      }
    }

    .cmp-specialevent-stage__logo {
      position: absolute;
      width: toRem(180px);

      @media (--breakpoint-up-lg) {
        top: var(--spacing-xxl);
        right: calc((var(--page-max-width) - 100%) / -2);
      }

      @media (--breakpoint-down-lg) {
        top: var(--spacing-md);
        right: var(--spacing-md);
      }

      @media (--breakpoint-down-sm) {
        width: toRem(100px);
      }
    }
  }

  .page-stagev2__wrapper--styling-none::after {
    display: none;
  }

  .cmp-breadcrumb {
    @media (--breakpoint-up-sm) {
      margin: var(--spacing-lg) 0;
    }

    @media (--breakpoint-down-sm) {
      margin: var(--spacing-xs) 0;
    }

    &__list {
      margin-right: var(--grid-margin);
      margin-bottom: 0;

      @media (--breakpoint-down-lg) {
        margin-left: toRem(28px);
      }

      @media (--breakpoint-down-sm) {
        margin-left: var(--spacing-lg);
      }

      @media (--breakpoint-up-lg) {
        max-width: var(--page-max-width);
        margin: 0 auto;
      }
    }
  }
}

@include remove-grid-content('.calltoaction');

:root {
  --rebrand-text-gradient-light: linear-gradient(to right top, #b63939, #d49496, #fff, #fff, #fcfcfc, #fff, #fefefe, #fff);
}

.cmp-calltoactionv2 {
  position: relative;

  .image {
    height: toRem(400px);

    * {
      height: 100%;
    }

    @media (--breakpoint-up-md) {
      img {
        border-radius: var(--border-radius-m);
      }
    }

    @media (--breakpoint-down-md) {
      height: toRem(320px);
    }

    @media (--breakpoint-down-sm) {
      height: toRem(200px);
    }
  }

  &-content {
    @media (--breakpoint-up-md) {
      position: absolute;
      top: 50%;
      width: toRem(446px);
      padding: toRem(21px) toRem(28px);
      border-radius: var(--border-radius-m);
      backdrop-filter: blur(var(--spacing-md));
      background: var(--color-white-op-60);
      border: var(--border-s) solid var(--color-gray-300);
    }

    @media (--breakpoint-down-md) {
      padding: toRem(21px) toRem(16px);
      background: var(--rebrand-text-gradient-light);
    }
  }

  &-title {
    margin-bottom: var(--spacing-lg);
  }

  .cmp-cta__button {
    margin-top: var(--spacing-lg);
  }

  @media (--breakpoint-up-md) {
    &--left {
      .cmp-calltoactionv2-content {
        left: var(--spacing-xxl);
        transform: translateY(-50%);
      }
    }

    &--right {
      .cmp-calltoactionv2-content {
        right: var(--spacing-xxl);
        transform: translateY(-50%);
      }
    }

    &--center {
      .cmp-calltoactionv2-content {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  @media (--breakpoint-down-md) {
    margin: 0 var(--grid-anti-margin) var(--spacing-lg);
  }
}

@include remove-grid-content('.ctamidpage');

.cmp-cta-midpage {
  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-4xl);

    @media (--breakpoint-down-md) {
      flex-direction: column;
      margin-bottom: var(--spacing-xl);
    }
  }

  &__image {
    @media (--breakpoint-up-md) {
      min-width: toRem(486px);

      img {
        border-radius: var(--border-xxl);
      }
    }

    @media (--breakpoint-down-md) {
      width: 100%;

      & * {
        height: 100%;
      }
    }

    @media (--breakpoint-down-sm) {
      height: toRem(200px);
    }
  }

  &__content {
    height: fit-content;
    background: var(--color-gray-700);
    color: var(--color-white);
    padding: toRem(21px) toRem(28px);
    width: 100%;

    @media (--breakpoint-up-md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--spacing-lg);
      margin-left: calc(var(--spacing-xxxl) * -1);
      border-radius: var(--border-xxl);
      border: var(--border-xs) solid var(--color-gray-300);

      &-title {
        margin-bottom: var(--spacing-sm);
      }
    }

    @media (--breakpoint-down-md) {
      .cmp-cta-midpage__content-description {
        margin-bottom: var(--spacing-lg);
      }
    }

    .cmp-cta__button {
      display: block;
      margin-top: 0;
      white-space: nowrap;
    }
  }

  &--bg-gray {
    .cmp-cta-midpage__wrapper {
      position: relative;
      margin: var(--spacing-6xl) 0;

      &::before {
        content: "";
        background: var(--color-gray-50);
        height: calc(100% + 6rem);
        z-index: -1;
        width: 100vw;
        position: absolute;
        margin-left: calc((100vw - 100%) / -2);

        @media (--breakpoint-down-md) {
          height: calc(100% + var(--spacing-xxxl));
          left: 0;
          top: calc(var(--spacing-lg) * -1);
        }
      }

      @media (--breakpoint-down-md) {
        margin-bottom: var(--spacing-5xl);
      }
    }
  }
}


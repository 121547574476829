@import '@react/common.scss';

//  Contacthub
.cmp-contacthub {
  &__title {
    font-weight: var(--font-weight);
    text-align: center;
  }

  &__back {
    display: inline-flex;
    align-items: baseline;
    padding: var(--spacing-xl) 0;
    cursor: pointer;

    &--title {
      @include bold-lg();

      color: var(--color-primary-500);
    }

    &--icon {
      color: var(--color-primary-500);
    }

    &:hover {
      .cmp-contacthub__back--title,
      .cmp-contacthub__back--icon {
        color: var(--color-primary-700);
      }
    }

    &:active {
      .cmp-contacthub__back--title,
      .cmp-contacthub__back--icon {
        color: var(--color-primary-800);
      }
    }

    &:hover,
    &:active {
      .cmp-contacthub__back--title {
        text-decoration: underline;
      }
    }
  }

  /* Animations */
  .exit.exit-active {
    opacity: 0;
    transform: translateX(-1.25rem);
  }

  .slide-fade-next-enter {
    opacity: 0;
    transform: translateX(1.25rem);
  }

  .slide-fade-next-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.3s ease;
  }

  .slide-fade-next-exit-active,
  .slide-fade-previous-exit-active {
    display: none;
  }
}

// ContactHubDrilldownItem

.cmp-contacthub__drilldown-item {
  position: relative;
  border: var(--spacing-xxxs) solid transparent;
  border-bottom: var(--spacing-xxxs) solid var(--color-gray-100);

  &:hover {
    outline: var(--color-primary-500) solid var(--spacing-xxxs);
    cursor: pointer;
    border-bottom: var(--spacing-xxxs) solid transparent;
    color: var(--color-primary-500);
  }

  &::before {
    position: absolute;
    top: calc(var(--spacing-xxxs) * -1);
    bottom: calc(var(--spacing-xxxs) * -1);
    left: calc(var(--spacing-xxxs) * -1);
    width: var(--spacing-xxs);
    background: var(--color-primary-500);
    content: '';
    transition: transform 0.25s;
    transform: scaleX(0);
    transform-origin: 0 0;
  }

  &:hover::before {
    transform: scaleX(1);
  }

  &--text {
    @include regular-lg();

    width: 100%;
    padding: var(--spacing-sm);
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &--directlink {
    color: var(--color-gray-900);
    text-decoration: none;
  }

  &--directlink:hover {
    color: var(--color-primary-500);
  }

  &-icon {
    font-size: var(--font-size-icon-sm);
    padding-right: var(--spacing-sm);
  }
}

:root {
  --nav-column-width: 15.4vw;
}

@media (--breakpoint-up-lg) {
  .cmp-navigation__content--mobile {
    display: none;
  }

  .cmp-navigation__nav-line {
    display: none;
  }

  .cmp-navigation,
  .cmp-navigation__content--desktop {
    display: flex;
  }

  .cmp-navigation {
    height: 100%;

    .cmp-navigation__column .cmp-navigation__item.cmp-navigation__item--level-1 {
      .cmp-navigation__overview-link {
        display: inherit;
        border-bottom: 1px solid var(--color-gray-100);
        padding-bottom: var(--spacing-xs);
        width: 100%;

        /* stylelint-disable-next-line selector-max-compound-selectors */
        .cmp-navigation__item-title {
          @include bold-xl();

          color: var(--color-primary-500);
          padding-bottom: var(--spacing-xs);
          margin-bottom: var(--spacing-xs);

          /* stylelint-disable-next-line selector-max-compound-selectors */
          &::after {
            font-family: var(--materialicons-font);
            content: var(--icon-keyboard_arrow_right);
            vertical-align: middle;
          }
        }
      }
    }

    .cmp-navigation__item.cmp-navigation__item--level-1.cmp-navigation__overview-item {
      width: 100%;
    }

    .cmp-navigation__item-link.cmp-navigation__overview-link:hover {
      color: var(--color-primary-500);
      text-decoration: underline;
      text-decoration-color: var(--color-primary-500);
      text-underline-offset: toRem(4px);
    }

    &__columns {
      color: var(--color-black);
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      align-content: flex-start;
    }

    &__column {
      color: var(--color-black);
      display: flex;
      flex-direction: column;
      flex: 1;
      flex-grow: 1;
      align-items: flex-start;
      margin-right: toRem(20px);
      max-width: toRem(282px);

      &:first-child:has(.cmp-navigation__overview-item) {
        width: 100%;
        max-width: 100%;
        flex: unset;
      }

      &:first-child {
        .cmp-navigation__item[aria-level='1'] {
          margin-bottom: var(--spacing-xs);
        }
      }

      .cmp-navigation__item {
        @include regular-md();

        display: table;
        color: var(--color-black);
        text-align: left;

        &[aria-level='1'] {
          @include bold-xl();

          color: var(--color-primary-500);
          margin: 0;
          border: var(--border-s) solid transparent;

          &:hover {
            color: var(--color-gray-900);
          }

          .cmp-navigation__item-title::after {
            font-family: var(--materialicons-font);
            content: var(--icon-keyboard_arrow_right);
            vertical-align: middle;
          }

          .cmp-navigation__item-title-manual {
            @include bold-lg();

            color: var(--color-gray-500);

            &:hover {
              color: var(--color-gray-500);
            }
          }
        }

        &[aria-level='1'] + .cmp-navigation__overview-item[aria-level='2'] {
          display: none;
        }

        &[aria-level='2'] + [aria-level='1'] {
          padding-top: var(--spacing-sm);
        }

        &[aria-level='1'] + [aria-level='1'] {
          padding-top: var(--spacing-md);
        }
      }

      .cmp-navigation__item.cmp-navigation__item-manual {
        &[aria-level='1'] {
          width: 100%;
          border-bottom: var(--border-xs) solid var(--color-gray-100);
          padding-bottom: var(--spacing-xxs);
          margin: var(--spacing-xs) 0 var(--spacing-xs);
        }
      }
    }

    &__column:nth-child(-n + 2) {
      margin-left: 0;
    }

    &__teasers {
      justify-self: flex-end;
    }
  }

  //For content creators to have the ability to style level-1 elements as level-2
  .cmp-navigation__group--l3 {
    .cmp-navigation {
      &__column {
        .cmp-navigation__item {
          &[aria-level='1'] {
            @include regular-md();

            color: var(--color-black);
            margin-bottom: var(--spacing-xs);
            border: var(--border-s) solid transparent;
            padding-top: 0;

            &:hover {
              color: var(--color-primary-500);
              text-decoration: underline;
              text-decoration-color: var(--color-primary-500);
              text-underline-offset: toRem(4px);
            }

            &:focus {
              border: var(--border-xs) solid var(--color-focus-500);
              border-bottom: 0;
            }
          }

          &--level-1.cmp-navigation__item--active {
            text-decoration: underline;
            text-decoration-color: var(--color-gray-900);
            text-underline-offset: toRem(4px);
          }

          &--level-1.cmp-navigation__overview-item.cmp-navigation__item--active {
            text-decoration: none;
          }

          &[aria-level='1']::after,
          .cmp-navigation__item-title::after {
            content: '';
          }
        }
      }
    }
  }

  .cmp-header-rebrand .cmp-navigation,
  .cmp-header-rebrand .cmp-navigation__content {
    cursor: pointer;
  }

  .cmp-navigation .cmp-navigation__nav-line {
    display: block;
    position: absolute;
    bottom: 0;
    height: var(--border-l);
    background-color: var(--color-primary-500);
    transition: 0.2s all ease;
  }

  .cmp-navigation > .cmp-navigation__title,
  .cmp-navigation > .cmp-navigation__back-button {
    display: none;
  }

  .cmp-navigation__link,
  .cmp-navigation__item--level-0 {
    font-weight: bold;
    border-top: var(--border-l) solid var(--color-black);
    border-bottom: var(--border-l) solid transparent;
  }

  .cmp-navigation__item--level-0 > .cmp-navigation__item-link {
    padding: 0.875rem var(--spacing-md);
    display: inline-block;
  }

  .cmp-navigation__link {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    padding: 0;
    position: relative;
  }

  .cmp-header-rebrand .cmp-navigation__item--level-0 {
    border-top: var(--border-l) solid transparent;
    margin: 0 auto 0 0;
  }

  .cmp-navigation__link p {
    padding: 0 var(--spacing-md);
  }

  .cmp-header-rebrand .cmp-navigation__link p {
    padding-right: var(--spacing-xxs);
  }

  .cmp-header--web.cmp-header-rebrand .cmp-navigation__item--level-1 {
    @include bold-md();
  }

  .cmp-header--power-tv.cmp-header-rebrand .cmp-navigation__link p,
  .cmp-header--intranet.cmp-header-rebrand .cmp-navigation__link p {
    padding: 0 var(--spacing-xxs);
  }

  .cmp-navigation__link p::after {
    content: '';
    border-left: var(--border-xs) solid var(--color-white);
    height: calc(100% - 2 * var(--spacing-xxs));
    position: absolute;
    right: 0;
    top: var(--spacing-xxs);
  }

  .cmp-header-rebrand .cmp-header__navigation .cmp-navigation__link p::after {
    display: none;
  }

  .cmp-header--intranet .cmp-navigation__item--level-0 {
    border-top-color: var(--color-gray-500);
  }

  .cmp-header--sticky .cmp-navigation__item--level-0 {
    @include regular-md();
  }

  .cmp-navigation__item--level-0:not(:hover, .cmp-navigation--expanded).cmp-navigation__item--active {
    border-bottom-color: var(--color-primary-500);
    background: var(--color-primary-500);
    color: var(--color-white);
  }

  .cmp-navigation__item--level-0 > .cmp-navigation__item {
    color: var(--color-white);
    align-items: center;
    display: flex;
  }

  .cmp-header-rebrand.cmp-header--sticky .cmp-navigation__item--level-0 {
    @include regular-lg();
  }

  .cmp-navigation__item--level-1 > .cmp-navigation__item {
    color: var(--color-primary-500);
  }

  .cmp-navigation__item--level-2 > .cmp-navigation__item {
    color: var(--color-black);
  }

  .cmp-header-rebrand .cmp-navigation__item--level-0 > .cmp-navigation__item {
    color: var(--color-gray-900);
  }

  .cmp-navigation__item--level-1 {
    margin-right: var(--spacing-md);
  }

  .cmp-navigation__item--level-1:nth-child(6) {
    margin-right: 0;
  }

  .cmp-navigation__item--level-1.cmp-navigation__item--active > .cmp-navigation__item,
  .cmp-navigation__item--level-1 .cmp-navigation__item--active > .cmp-navigation__item {
    border-bottom: var(--border-s) solid var(--color-primary-500);
  }

  .cmp-navigation__item--level-0:hover > .cmp-navigation__item,
  .cmp-navigation__item--level-0.cmp-navigation__item--expanded > .cmp-navigation__item,
  .cmp-navigation__item--level-0:hover,
  .cmp-navigation__item--level-0.cmp-navigation__item--expanded {
    color: var(--color-white);
    background: var(--color-primary-500);
    text-decoration: none;
  }

  .cmp-navigation__item--level-1 > .cmp-navigation__item:hover {
    color: var(--color-gray-900);
  }

  .cmp-navigation__item--level-0 > .cmp-navigation__group {
    display: none;
    position: absolute;
    z-index: -1;
    top: 0%;
    left: 0;
    width: 100%;
    background: var(--color-white);
    opacity: 0;
    padding: var(--spacing-lg) var(--spacing-xxl) var(--spacing-lg);
    box-shadow: var(--box-shadow);
    overflow: hidden;
    max-height: 0;
    transition:
      max-height 0.4s ease-out,
      opacity 0.4s ease-out,
      top 0.4s ease-out,
      display 0.4s ease-out;
    /* stylelint-disable-next-line property-no-unknown */
    transition-behavior: allow-discrete;

    .cmp-navigation__group--wrapper {
      display: grid;
      grid-template-columns: auto auto;
      max-width: var(--page-max-width);
      margin: 0 auto;
    }
  }

  .cmp-navigation__item--level-0.cmp-navigation__item--expanded {
    & > .cmp-navigation__group {
      display: block;
      max-height: calc(100vh - var(--spacing-4xl));
      top: 100%;
      opacity: 1;
      overflow-y: scroll;
    }

    &:not(.cmp-navigation__item--selected) > .cmp-navigation__group .cmp-mddpromotion {
      opacity: 0;
      animation: navigation-mdd-promotion-up 0.4s ease-in-out 0.4s 1 forwards;
    }
  }

  /* stylelint-disable-next-line scss/at-rule-no-unknown */
  @starting-style {
    .cmp-navigation__item--level-0.cmp-navigation__item--expanded > .cmp-navigation__group {
      max-height: 0;
      padding: 0 var(--spacing-xxl) 0;
      top: 0%;
      opacity: 0;
    }
  }

  .cmp-navigation:has(.cmp-navigation__item--expanded) .cmp-navigation__item--level-0:not(.cmp-navigation__item--expanded) > .cmp-navigation__group {
    visibility: hidden;
  }

  .cmp-navigation__item--expanded .cmp-navigation__item--level-1 .cmp-navigation__group {
    display: block;
  }

  .cmp-navigation__item.cmp-navigation__item--expanded:hover {
    cursor: auto;
  }

  .cmp-header-rebrand .cmp-navigation__item--level-0.cmp-navigation__item--active {
    > .cmp-navigation__item-link {
      @include bold-md();

      display: flex;
      align-items: center;
      text-align: center;
      border: 0;
      position: relative;
      pointer-events: all;
    }
  }

  .cmp-navigation__link-logo {
    position: relative;
  }

  .cmp-navigation__item--level-2 {
    margin: var(--spacing-xxs) var(--spacing-md) var(--spacing-xxs) toRem(2px);
    line-height: var(--line-height-sm);
    break-inside: avoid;
    border: var(--border-xs) solid transparent;
  }

  .cmp-navigation__item--level-2.cmp-navigation__item--level-2:hover {
    color: var(--color-primary-500);
    text-decoration: underline;
    text-decoration-color: var(--color-primary-500);
    text-underline-offset: toRem(4px);
  }

  .cmp-navigation__item--level-2:focus {
    border: var(--border-xs) solid var(--color-focus-500);
  }

  .cmp-navigation__item--level-2.cmp-navigation__item--active {
    text-decoration: underline;
    text-decoration-color: var(--color-gray-900);
    text-underline-offset: toRem(4px);
  }

  .hide-level-2 .cmp-navigation__item--level-2 {
    display: none;
  }

  /* we never show these items in the mega dropdown as per design */
  .cmp-navigation__item--level-3 {
    display: none;
  }

  .cmp-navigation--expanded .cmp-navigation__overlay {
    top: 100%;
    position: absolute;
    height: 100vh;
    width: 100%;
    left: 0;
    background: var(--color-black-transparent);
  }

  .cmp-navigation__item--level-1 > .u-no-link {
    color: var(--color-black);
    cursor: initial;
  }

  .cmp-navigation__item--level-1 > .u-no-link::after {
    content: ' ';
  }

  .cmp-header--intranet.cmp-header-rebrand .cmp-navigation__item--level-0,
  .cmp-header--power-tv.cmp-header-rebrand .cmp-navigation__item--level-0 {
    color: var(--color-white);
  }

  .cmp-navigation__group {
    > .cmp-navigation__item--level-0 {
      border: 0;
      height: 100%;

      > .cmp-navigation__item-link {
        @include bold-md();

        display: flex;
        align-items: center;
        text-align: center;
        border: 0;
        position: relative;
        pointer-events: all;
        height: 100%;
      }
    }
  }

  .cmp-header--intranet .cmp-header__navigation-wrapper,
  .cmp-header--power-tv .cmp-header__navigation-wrapper {
    background: var(--color-gray-500);
  }
}

@media #{$mq-6} {
  header .cmp-experiencefragment--header {
    .cmp-header.cmp-header--sticky > .cmp-header__navigation-wrapper {
      width: 100%;
      position: fixed;
      height: var(--spacing-xxxl);
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
    }

    .cmp-header--sticky .cmp-header__navigation {
      position: relative;
    }

    .cmp-header__top-content-wrapper {
      z-index: 2;
      position: relative;
      background-color: var(--color-white);
    }

    .cmp-header__top-content,
    .cmp-header__navigation {
      margin: 0 auto;
      box-shadow: none;
      position: relative;

      &.cmp-button--sticky {
        position: absolute;
        right: 0;
      }
    }

    .cmp-header--sticky .cmp-header__top-item-content,
    .cmp-header__top-item-content {
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      width: 100vw;
    }

    .cmp-header__top-item.cmp-header__logout .cmp-header__top-item-content {
      left: unset;
      transform: unset;
      width: 100%;
    }

    .cmp-header__overlay {
      z-index: 0;
    }

    .cmp-navigation__item--level-0 > .cmp-navigation__group {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      padding: 0;
    }

    /* stylelint-disable-next-line selector-max-compound-selectors */
    .cmp-navigation__item--level-0 > .cmp-navigation__group .cmp-navigation__group--wrapper {
      padding: var(--spacing-lg) 0;
    }
  }
}

.cmp-teasercontainer--toolbox .cmp-teaser__tag {
  @include regular-xs();

  color: var(--color-gray-900);
  margin-bottom: var(--spacing-xxs);
}

.cmp-teasercontainer--toolbox .cmp-teaser__title-text {
  @include heading-5;

  color: var(--color-gray-900);
  margin: 0 0 var(--spacing-lg);
}

.cmp-teasercontainer--toolbox .cmp-teaser {
  &:hover {
    .cmp-teaser__title-text {
      color: var(--color-primary-500);
    }
  }
}

.cmp-teasercontainer--toolbox .cmp-teaser__image,
.cmp-teasercontainer--toolbox .cmp-teaser__description {
  display: none;
}

.cmp-teasercontainer--toolbox .cmp-teaser__title-link::after,
.cmp-teasercontainer--toolbox .cmp-teaser__action-link:first-child::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

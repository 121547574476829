/**
 * Linklist -Component
 */

.cmp-linklist__heading {
  @include regular-lg();
  @include separation-border();

  position: relative;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
}

.cmp-linklist__title {
  @include bold-lg();

  color: var(--color-gray-900);
  display: flex;
  align-items: center;
  margin: 0;
}

.cmp-linklist__global {
  display: inline-block;
  height: 100%;
  text-align: right;
}

.cmp-linklist__global-link {
  color: var(--color-primary-500);
  white-space: nowrap;
  display: inline-flex;

  &:hover {
    color: var(--color-gray-900);

    .cmp-linklist__global-link-text {
      text-decoration: underline;
    }
  }

  .cmp-linklist__global-icon {
    display: inline-block;
  }
}

.cmp-linklist__items--featured .cmp-linklist__item-link-text {
  align-self: flex-end;
  margin-bottom: 0.125rem;
}

.cmp-linklist__global-link .cmp-linklist__global-icon::before {
  font-weight: var(--font-weight-bold);
}

.cmp-linklist__grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: calc(-1 * var(--spacing-lg));
}

.cmp-linklist__col {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin: var(--spacing-lg) var(--spacing-lg) var(--spacing-lg) 0;
  width: 100%;
}

/* link-item */
.cmp-linklist__item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  border-radius: var(--border-radius-m);

  &:hover {
    .cmp-linklist__item-link-text {
      color: var(--color-primary-500);
    }

    .cmp-linklist__item-link-icon {
      background-color: var(--color-primary-500);
    }

    .cmp-linklist__item-wrapper {
      box-shadow: var(--box-shadow-md);
    }
  }

  &:has(.cmp-linklist__item-wrapper:focus) {
    outline: var(--border-m) solid var(--color-focus-500);
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: var(--color-gray-50);
    border-radius: var(--border-radius-m);
    box-shadow: var(--box-shadow);
    overflow: auto;
  }

  & + .cmp-linklist__item {
    margin-top: var(--spacing-lg);
  }

  &-image {
    overflow: auto;
    position: relative;

    .cmp-image {
      position: relative;
      width: 100%;
      height: 100%;

      &::before {
        content: '';
        display: block;
        padding-top: 56.25%;
      }
    }

    .cmp-responsive-image img,
    .cmp-image__title {
      position: absolute;
    }

    .cmp-responsive-image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &-meta {
    justify-content: center;
    padding: var(--spacing-md);
    width: 100%;
  }

  &-date,
  &-location,
  &-readingTime {
    @include regular-xs();

    color: var(--color-gray-900);
    letter-spacing: 0;
  }

  &-location {
    text-transform: uppercase;
  }

  &-link {
    @include bold-lg();

    display: flex;
    gap: var(--spacing-xxs);
    color: var(--color-gray-900);
  }

  &-link-icon {
    background-color: var(--color-gray-900);
    color: var(--color-white);
    height: 100%;
    border-radius: var(--border-radius-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    &.icon-keyboard_arrow_right::before {
      margin: 0;
    }
  }

  &-desc {
    @include regular-lg();

    color: var(--color-gray-900);
  }

  &-title {
    @include bold-lg();

    margin: 0;
  }

  &-image-badge {
    @include bold-2xl();

    position: absolute;
    top: var(--spacing-lg);
    left: 0;
    background: var(--color-primary-500);
    color: var(--color-white);
    padding: var(--spacing-xxs) var(--spacing-xs);
    border-radius: 0 var(--spacing-xxs) var(--spacing-xxs) 0;
    z-index: 1;
  }

  /* Image position - Policies */
  &-image.cq-Editable-dom,
  &-image .cq-dd-image {
    min-height: 3.125rem; /* 50 */
  }
}

.cmp-linklist__items--reverse {
  .cmp-linklist__item {
    &-meta {
      display: flex;
      flex-direction: column-reverse;
    }

    &-link {
      margin-top: var(--spacing-xs);

      .cmp-linklist__item-link-icon {
        display: block;
      }
    }
  }

  &.cmp-linklist__items--with-shadow .cmp-linklist__item-date {
    margin-bottom: var(--spacing-xs);
  }
}

.cmp-linklist__items--with-shadow .cmp-linklist__item {
  box-shadow: var(--box-shadow);
}

.cmp-linklist__items--card {
  .cmp-linklist__item-link-icon,
  .cmp-linklist__item-link .icon-keyboard_arrow_right,
  .cmp-linklist__item-link .icon-description {
    display: none;
  }

  .cmp-linklist__item {
    min-height: 6.25rem; /* 100 */
    overflow: hidden;
  }

  .cmp-image {
    position: initial;
  }

  .cmp-image::before {
    display: none;
  }

  .cmp-linklist__item-meta {
    align-items: center;
    background: linear-gradient(to right, rgb(0 0 0 / 15%), rgb(0 0 0 / 65%));
    display: flex;
    flex-grow: 1;
    justify-content: center;
    z-index: 1;
    text-align: center;
  }

  .cmp-linklist__item-link {
    color: var(--color-white);

    .cmp-linklist__item-link-icon {
      display: none;
    }
  }

  .cmp-linklist__item-desc,
  .cmp-linklist__item-date {
    display: none;
  }
}

.cmp-linklist__image--left:not(.cmp-linklist__items--card) {
  .cmp-linklist__item {
    flex-direction: row;
  }

  .cmp-linklist__item-image {
    padding: var(--spacing-md) 0;
    flex: 1 0 auto;
    width: 40%;
  }

  .cmp-image {
    height: initial;
  }
}

.cmp-linklist__item-link .cmp-linklist__item-link-icon:first-of-type,
.cmp-linklist__item-details {
  display: none;
}

.cmp-linklist__items--featured {
  .cmp-linklist__item-link .cmp-linklist__item-link-icon:first-of-type {
    @include regular-3xl();
  }

  .cmp-linklist__item-details p {
    margin-bottom: var(--spacing-xxs);
    display: inline-block;
  }

  .cmp-linklist__item-date:last-of-type,
  .cmp-linklist__item-link .cmp-linklist__item-link-icon:last-of-type,
  .cmp-linklist__item-desc {
    display: none;
  }
}

.linklist.cmp-linklist__items--card {
  .cmp-linklist__item:hover .cmp-linklist__item-link-text {
    text-decoration: underline;
    color: var(--color-white);
  }

  .cmp-linklist__item-image {
    position: initial;
  }

  .cmp-linklist__item-image-badge {
    display: none;
  }
}

.linklist:not(.cmp-linklist__items--card).cmp-linklist__items--featured {
  .cmp-linklist__item-details,
  .cmp-linklist__item-link .cmp-linklist__item-link-icon:first-of-type {
    display: block;
  }
}

.cmp-teaser__meta-separator {
  vertical-align: middle;
}

/* Gutters */
@media (--breakpoint-down-lg) {
  .cmp-linklist__grid {
    margin-right: calc(-1 * var(--spacing-sm));
  }

  .cmp-linklist__col {
    margin: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) 0;
  }
}

/* Mobile - styles */
@media (--breakpoint-down-md) {
  .cmp-linklist__grid {
    flex-direction: column;
    margin: 0;
  }

  .cmp-linklist__col {
    margin-bottom: 0;
    margin-right: 0;
  }
}

.messaging-alert__wrapper {
  display: none;
  background: var(--color-gray-50);
  padding: 0.938rem 12.5rem 1.125rem 4.25rem;
}

.messaging-alert__title {
  @include bold-md();

  color: var(--color-primary-500);
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-xxs);
}

.messaging-alert__title-text {
  font-weight: var(--font-weight-bold);
}

.messaging-alert__title .icon-alert {
  font-size: var(--spacing-lg);
  display: flex;
  align-items: center;
}

.messaging-alert__text {
  @include regular-md();

  margin-left: 1.875rem;
}

.messaging-alert__link {
  @include bold-md();

  color: var(--color-primary-500);
}

.messaging-alert__info-text {
  padding-right: var(--spacing-md);
}

@media (--breakpoint-down-md) {
  .messaging-alert__wrapper {
    padding: 1.125rem 1.375rem 1.625rem 0.938rem;
  }
}

.cmp-author__wrapper {
  display: flex;

  @media #{$mq-1} {
    flex-direction: column;
  }

  .cmp-author__image {
    align-self: center;

    @media #{$mq-1} {
      width: 100%;
    }

    img {
      border-radius: var(--border-radius-m) 0 0 var(--border-radius-m);

      @media #{$mq-1} {
        border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
      }
    }

    .cmp-responsive-image {
      position: relative;
      aspect-ratio: 1/1;
      height: toRem(282px);

      @media #{$mq-1} {
        height: 100%;
      }
    }
  }

  .cmp-author__wrapper-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: toRem(21px) var(--spacing-lg);

    @media #{$mq-1} {
      width: 100%;
      padding: var(--spacing-lg) var(--spacing-md);
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background: url('/src/main/webpack/resources/textMediaLargePattern.png');
      transform: rotate(180deg);
      border-radius: var(--border-radius-m) 0 0 var(--border-radius-m);

      @media #{$mq-1} {
        border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
      }
    }

    .cmp-author__name,
    .cmp-author__job-title,
    .cmp-author__description {
      margin-bottom: var(--spacing-xs);
    }

    .cmp-author__description,
    .cmp-author__links {
      @include regular-lg();

      margin-top: var(--spacing-xs);
    }

    .cmp-author__description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media #{$mq-1} {
        display: flex;
      }

      @media #{$mq-desktop} {
        -webkit-line-clamp: 4;
        line-clamp: 4;
      }

      @media #{$mq-2} {
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }

      @media #{$mq-3} {
        -webkit-line-clamp: 3;
        line-clamp: 3;
      }

      a {
        color: var(--color-primary-500);
        text-decoration: underline;

        &:hover {
          color: var(--color-black);
        }
      }
    }

    .cmp-author__links {
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-md);
    }

    .cmp-author__link {
      @include regular-lg();

      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-gray-900);
      padding: var(--spacing-xxs) var(--spacing-md) var(--spacing-xxs) 0;

      &:not(:last-child) {
        border-right: var(--border-xs) solid var(--color-gray-900);
      }

      &--icon {
        display: none;

        &::before {
          margin-right: 0;
        }
      }

      &--text {
        display: flex;
        text-decoration: underline;
        color: var(--color-primary-450);

        &:hover {
          color: var(--color-primary-500);
        }
      }
    }
  }
}

@import 'src/main/webpack/site/styles/foundations/media_queries';

.cmp-react-carousel {
  display: none;
}

@media #{$mq-1} {
  .cmp-react-carousel {
    display: block;
    padding-right: var(--spacing-lg);

    &__progress {
      background: var(--color-gray-100);
      height: var(--spacing-xs);
      width: 100%;
      transform: skew(-30deg);
    }

    &__progress-status {
      height: 100%;
      background: var(--color-primary-500);
    }

    &__controls {
      display: flex;
      justify-content: center;
      margin: var(--spacing-sm) 0 0;
      column-gap: var(--spacing-lg);
    }

    &__control-prev,
    &__control-next {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--spacing-xl);
      height: var(--spacing-xl);
      background-color: var(--color-primary-500);
      border: var(--border-xs) solid var(--color-primary-500);
      animation: splide-arrow-hover-reverse 0.15s ease-in-out 0s 1 forwards;

      &:hover {
        animation: splide-arrow-hover 0.15s ease-in-out 0s 1 forwards;
        background-color: var(--color-primary-700);
      }

      &:active {
        background-color: var(--color-primary-800);
      }
    }

    &__controls-disabled {
      opacity: 0.8;
      pointer-events: none;
    }

    .icon-keyboard_arrow_left,
    .icon-keyboard_arrow_right {
      font-size: var(--font-size-icon-md);
      margin: 0;
      color: var(--color-white);

      &::before {
        margin-right: 0;
      }
    }
  }
}

/*
* AEM Component
* Accordion
*/

.cmp-accordion {
  display: flex;
  width: 100%;
  flex-flow: column;
  gap: var(--spacing-md);
}

.cmp-accordion__item {
  position: relative;
  border-radius: var(--border-radius-xs);

  &[data-cmp-expanded] {
    outline: var(--border-xs) solid var(--color-gray-700);

    .cmp-accordion__header {
      color: var(--color-white);
      background-color: var(--color-gray-700);
    }

    .cmp-accordion__icon {
      color: var(--color-gray-900);
      background-color: white;
    }
  }

  .aem-GridColumn:not(:last-child).button {
    @include add-content-padding();
  }
}

.cmp-accordion__header {
  color: var(--color-gray-900);
  background: var(--color-gray-50);

  &:hover {
    cursor: pointer;
    background: var(--color-gray-100);

    .cmp-accordion__icon {
      background-color: var(--color-primary-500);
      color: var(--color-white);
    }
  }
}

.cmp-accordion__item:not([data-cmp-expanded]) .cmp-accordion__header {
  position: relative;
  border-radius: var(--border-radius-xs);

  .cmp-accordion__button {
    &:focus,
    &:focus-visible {
      outline: var(--border-m) solid var(--color-focus-500);
    }
  }
}

.cmp-accordion__header .cmp-accordion__button {
  display: flex;
  align-items: center;
  padding: toRem(14px) var(--spacing-sm) toRem(14px) var(--spacing-lg);
}

.cmp-accordion__title {
  @include bold-lg;

  width: 98%;
}

.cmp-accordion__icon {
  background: var(--color-gray-900);
  color: var(--color-white);
  border-radius: var(--border-radius-xs);
  display: flex;
}

.cmp-accordion__button--expanded .cmp-accordion__icon.icon-add {
  display: none;
}

.cmp-accordion__icon.icon-remove {
  display: none;

  &::before {
    margin-right: 0;
  }
}

.cmp-accordion__icon.icon-add {
  &::before {
    margin-right: 0;
  }
}

.cmp-accordion__button--expanded .cmp-accordion__icon.icon-remove {
  display: block;
}

.cmp-accordion__panel {
  padding: var(--spacing-lg);

  &.cmp-accordion__panel--expanded {
    background: var(--color-white);
    display: flex;
  }
}

/**
 * Carousel - Component
 */

@include remove-grid-content('.carousel.eventList');

.cmp-carousel iframe {
  height: 100%;
  width: 100%;
}

.cmp-carousel .cmp-banner-cta__content--full-width,
.cmp-carousel :not(.cmp-quote__image) > .cmp-image {
  background: linear-gradient(270deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 40%) 100%);
}

.cmp-carousel .cmp-banner-cta__arrow,
.cmp-container--full-width .cmp-carousel .cmp-banner-cta__arrow {
  border-top-width: calc(37.5em + 20vh);
}

/* actions */
.cmp-carousel__actions {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;
  padding: var(--spacing-md);
  flex-flow: column;
}

.cmp-carousel__action {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: var(--spacing-xl);
  width: var(--spacing-xl);
  background-color: var(--color-gray-100);
  color: var(--color-gray-900);
  border-radius: var(--border-radius-m);
  padding: var(--spacing-xxs);
  border: var(--border-xs);
  min-width: unset;

  &:hover {
    background-color: var(--color-primary-500);
    color: var(--color-white);
  }

  &:focus {
    outline: var(--border-m) solid var(--color-focus-500);
    background-color: var(--color-primary-500);
    color: var(--color-white);
  }

  .cmp-carousel__action-icon::before {
    font-size: var(--spacing-lg);
    margin: 0;
  }
}

/* if only 1 slide is available, the action menu is not needed */
.cmp-carousel__content > div:nth-child(2):last-child {
  display: none;
}

.cmp-carousel__current-item {
  @include regular-md;

  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--spacing-xl);
  height: var(--spacing-xl);

  &--hidden {
    display: none;
  }
}

.cmp-carousel__indicators {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  margin: 0;

  &--hidden {
    display: none;
  }
}

.cmp-carousel__action-text {
  display: none;
}

@media (hover: none) {
  .cmp-carousel__action:hover {
    background-color: var(--color-primary-500);
  }
}

.cmp-carousel__indicator {
  margin: 0 var(--spacing-xs);
  pointer-events: auto;
  background-color: var(--color-gray-300);
  width: var(--spacing-xs);
  height: var(--spacing-xs);
  border-radius: 50%;
  cursor: pointer;
}

.cmp-carousel__arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: var(--spacing-lg);
}

.cmp-carousel__arrows .cmp-carousel__action--previous {
  margin-right: var(--spacing-md);
}

.cmp-carousel__arrows .cmp-carousel__action--next {
  margin-left: var(--spacing-md);
}

/* stage - when inside stage hero */
.stage .cmp-carousel__actions {
  position: absolute;
  bottom: 0;
}

.stage .cmp-carousel__action-icon::before {
  color: white;
}

/* active state  */
.cmp-carousel .cmp-carousel__indicator--active {
  background-color: var(--color-black);
}

/* Only desktop - styles */
@media (--breakpoint-up-sm) {
  .cmp-carousel .cmp-banner-cta__title {
    font-size: var(--font-size-h1);
    line-height: var(--line-height-h1);
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .cmp-carousel .cmp-text {
    margin-top: var(--spacing-sm);
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .cmp-carousel .cmp-banner-cta__content--side-aligned .cmp-banner-cta__button {
    margin-bottom: 5rem; /* 80 */
  }
}

/* Mobile - styles */
@media (--breakpoint-down-sm) {
  .cmp-carousel__actions {
    margin: 0 10%;
    padding-bottom: var(--spacing-md);
    width: 80%;
  }

  .cmp-container--full-width .cmp-carousel .cmp-banner-cta__button {
    margin-bottom: var(--spacing-md);
  }
}

.cmp-specialevent-stage {
  display: flex;
  gap: var(--spacing-lg);

  @media #{$mq-1} {
    flex-direction: column;
    gap: var(--spacing-sm);
  }

  &__image {
    max-width: toRem(383px);
    width: 100%;

    .cmp-responsive-image {
      aspect-ratio: 1 / 1;

      @media #{$mq-1} {
        aspect-ratio: 16 / 9;
      }

      img {
        border-radius: var(--border-radius-m);
      }
    }

    @media #{$mq-1} {
      max-width: 100%;
    }
  }

  &__content-wrapper {
    width: 100%;

    .cmp-stage-page-intro {
      &__meta {
        margin-bottom: 0;
      }

      &__meta-item {
        margin-bottom: var(--spacing-md);

        .cmp-stage-page-intro__meta-item {
          margin-bottom: 0;
        }
      }

      &__meta-item__text {
        @include bold-lg();

        margin-left: var(--spacing-sm);
      }

      &__meta-item__icon {
        color: var(--color-black);

        &::before {
          margin-right: 0;
        }

        & + .cmp-stage-page-intro__meta-item__text {
          margin-left: var(--spacing-sm);
        }
      }

      &__tags {
        margin-bottom: var(--spacing-sm);
      }
    }

    .cmp-sharing {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 0;

      &__link {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .cmp-sharing__icon {
          margin: 0 0 0 var(--spacing-sm);
        }
      }
    }
  }

  &__content {
    &-pre-title {
      margin-bottom: var(--spacing-sm);
    }

    &-title {
      margin-bottom: var(--spacing-xl);

      @media #{$mq-1} {
        margin-bottom: var(--spacing-lg);
      }
    }
  }
}

/* stylelint-disable selector-max-compound-selectors */
.slide-root {
  .slide-wrapper {
    .cmp-cta__image {
      &::after {
        content: none;
      }
    }

    .button {
      justify-content: flex-start;
    }

    .image-wrapper,
    .image-wrapper .cmp-responsive-image {
      height: 100%;
      width: 100%;
    }

    .cmp-banner-cta__content-inner {
      margin: 0 0 var(--spacing-xxxl) var(--spacing-xxxl);

      .slide-inner__preline {
        @include regular-md;

        margin-bottom: var(--spacing-sm);
        text-transform: uppercase;
      }

      .slide-title {
        @include bold-8xl;

        margin-bottom: var(--spacing-sm);
      }

      & .slide-text {
        @include regular-xl;

        margin: 0;

        p {
          margin-bottom: var(--spacing-sm);
        }
      }

      .cmp-banner-cta__button {
        margin: var(--spacing-sm) 0 0 0;

        & .cmp-button {
          margin-top: 0;
        }
      }
    }
  }

  .slide-video-root {
    position: absolute;
    width: 100%;
  }

  .cmp-pagestage__dynamicmediaplayer {
    max-height: 100%;
  }

  .cmp-banner-cta__content--darkTrapezoid {
    background-color: var(--color-black-transparent-lighter);
  }

  .slide-wrapper--darkTrapezoid .cmp-banner-cta__arrow {
    border-top-color: var(--color-black-transparent-lighter);
  }
}

// Full overlay
.slide-wrapper--fullOverlay::after,
.slide-wrapper--leftOverlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.slide-wrapper--fullOverlay::after {
  background: linear-gradient(180deg, rgb(0 0 0 / 0.01%) 0%, rgb(0 0 0 / 0%) 0.01%, rgb(0 0 0 / 75%) 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.slide-wrapper--leftOverlay::after {
  background: linear-gradient(90deg, rgb(0 0 0 / 0.01%) 0%, rgb(0 0 0 / 60%) 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.slide__brandingPattern {
  @include brandingPattern;
}

@media (--breakpoint-down-md) {
  .slide-root {
    .slide-wrapper {
      .cmp-banner-cta__content-inner {
        margin: var(--spacing-lg);
        padding: 0;

        .slide-title {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          line-clamp: 4;
          overflow: hidden;

          @include bold-3xl;

          margin: 0 0 var(--spacing-sm) 0;
        }

        .slide-text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
          margin: 0;
        }

        .cmp-banner-cta__button {
          margin: 0;
        }

        .slide-inner__preline {
          @include regular-sm;

          text-transform: uppercase;
          margin: 0 0 var(--spacing-sm) 0;
          white-space: nowrap;
          display: flex;
          flex-wrap: wrap;
          gap: toRem(4px);
        }
      }
    }
  }
}

@media (--breakpoint-down-sm) {
  .cmp-banner-cta__content--side-aligned .cmp-banner-cta__content-inner {
    width: unset;
  }

  .cmp-banner-cta__content--darkTrapezoid {
    background-color: var(--color-black-transparent-lighter);
  }

  .slide-wrapper--darkTrapezoid .cmp-banner-cta__arrow::before {
    border-left-color: var(--color-black-transparent-lighter);
  }

  .slide {
    height: 70vh;

    .slide-root {
      height: 70vh;

      .slide-wrapper {
        .cmp-banner-cta__content--redTrapezoid {
          .cmp-banner-cta__content-inner {
            background: var(--color-primary-500);
          }
        }

        .cmp-banner-cta__content--darkTrapezoid {
          .cmp-banner-cta__content-inner {
            background: var(--color-black-transparent-lighter);
          }
        }

        .cmp-banner-cta__content {
          .cmp-banner-cta__content-inner {
            position: static;
            margin: 0;
            padding: var(--spacing-lg) var(--spacing-lg) toRem(72px) var(--spacing-lg);

            .cmp-button {
              margin: 0;
            }
          }
        }

        .cmp-banner-cta__content--redTrapezoid,
        .cmp-banner-cta__content--darkTrapezoid {
          position: static;
          height: 100%;
        }

        .slide__mobile-trapezoid {
          height: toRem(215px);
          position: relative;
        }

        .cmp-banner-cta__content--redTrapezoid .slide__mobile-trapezoid::before,
        .cmp-banner-cta__content--darkTrapezoid .slide__mobile-trapezoid::before {
          content: '';
          position: absolute;
          bottom: 0;
          border-top: 15vw solid transparent;
          border-left-style: solid;
          border-left-width: 100vw;
          border-top: 25vw solid transparent;
        }

        .cmp-banner-cta__content--redTrapezoid .slide__mobile-trapezoid::before {
          border-right: 100vw solid var(--color-primary-500);
          border-left-color: var(--color-primary-500);
        }

        .cmp-banner-cta__content--darkTrapezoid .slide__mobile-trapezoid::before {
          border-right: 100vw solid var(--color-black-transparent-lighter);
          border-left-color: var(--color-black-transparent-lighter);
        }

        .cmp-pagestage__dynamicmediaplayer video {
          height: 100%;
        }
      }
    }

    .cmp-banner-cta__arrow {
      display: none;
    }
  }

  .cmp-container--full-width .cmp-banner-cta__wrapper.slide-wrapper {
    height: 70vh;
    max-height: none;
  }
}

.blogpostpage-v2 .cmp-separator {
  height: toRem(3px);
  background: var(--color-gray-100);

  .cmp-separator__horizontal-rule {
    width: 50%;
    float: left;
    border: 0;
    background: var(--color-primary-500);
    margin: 0;
    height: toRem(3px);
  }
}

/* stylelint-disable declaration-no-important */

.cmp-mediatext-large {
  display: flex;
  color: var(--color-gray-900);
  width: 100%;
  max-width: var(--page-max-width);
  margin: 0 auto;

  &:has(video) {
    .cmp-mediatext-large__content-link-button {
      @include button-secondary;
    }
  }

  @media #{$mq-1} {
    flex-direction: column;
  }

  &--content-wrapper {
    width: 100%;
    padding: 0 var(--spacing-lg);
  }

  &__media {
    width: 57.5%;

    @media #{$mq-1} {
      width: 100%;
    }

    img {
      border-radius: var(--border-radius-m);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 42.5%;
    padding: var(--spacing-lg);

    @media #{$mq-1} {
      width: 100%;
      padding: var(--spacing-md) 0 0;
    }

    &-pre-title,
    &-pre-title p {
      @include bold-md();

      margin-bottom: var(--spacing-xs);
    }

    &-title {
      margin-bottom: var(--spacing-xs);

      * {
        margin: 0;
      }
    }

    &--subtitle {
      margin-bottom: var(--spacing-md);
    }

    &-description {
      @include regular-lg();

      margin-bottom: var(--spacing-md);

      a {
        display: inline-block;
        color: var(--color-primary-500);
        text-decoration: underline;

        &:hover {
          color: var(--color-primary-700);
        }
      }
    }

    &-link-button {
      @include button-primary();

      width: fit-content;
    }
  }

  &.cmp-mediatext-large--media-on-right {
    .cmp-mediatext-large__media {
      order: 2;
    }

    .cmp-mediatext-large__content {
      order: 1;

      @media #{$mq-1} {
        padding: 0 0 var(--spacing-md);
      }
    }
  }

  .cmp-responsive-image,
  .cmp-video-wrapper {
    aspect-ratio: 16/9;
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }

  .cmp-video-wrapper {
    display: flex;
    position: relative;
    cursor: default;

    .cmp-video-wrapper__poster-img {
      cursor: pointer;
    }
  }

  &:has(.cmp-video-wrapper) {
    background-color: var(--color-black);
    backdrop-filter: blur(var(--spacing-md));
    color: var(--color-white);

    @media #{$mq-1} {
      background-color: transparent;
      color: var(--color-900);
    }

    .cmp-mediatext-large__content-description {
      a {
        color: var(--color-primary-500);

        &:hover {
          color: var(--color-white);
        }
      }
    }
  }
}

.mediatextlarge {
  margin-left: calc((100vw - 100%) / -2);
  width: 100vw !important;
  padding: 0 !important;

  &.cmp-media-text-large-variant-pattern .cmp-mediatext-large--content-wrapper {
    background: url('/src/main/webpack/resources/textMediaLargePattern.png');
    padding: var(--spacing-lg);
  }
}

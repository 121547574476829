@media (--breakpoint-down-md) {
  .cmp-teasercontainer.cmp-teasercontainer--image-and-headline-mini .teaser,
  .cmp-teasercontainer.cmp-teasercontainer--image-and-headline-mini .basicpage {
    margin-bottom: var(--spacing-xs);
  }
}

.cmp-teasercontainer--image-and-headline-mini {
  .cmp-teaser {
    flex-direction: row;

    &:hover .cmp-teaser__title-text {
      color: var(--color-primary-500);
    }
  }

  .cmp-teaser__image {
    width: 20%;
    aspect-ratio: 1;
  }

  .cmp-teaser__content {
    padding: var(--spacing-md);
    width: 70%;
  }

  .cmp-teaser__content-link {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .cmp-teaser__tag,
  .cmp-teaser__description {
    display: none;
  }

  .cmp-teaser__title .cmp-teaser__title-text {
    @include heading-5;

    color: var(--color-gray-900);
    margin: 0 0 var(--spacing-lg);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: unset;
  }

  .cmp-teaser__title-link::after,
  .cmp-teaser__action-link:first-child::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

@include remove-grid-content('.teasercontainer');

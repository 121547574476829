.cmp-videoembed {
  padding-top: 56.25%;
  overflow: hidden;
  height: 0;
  position: relative;
  margin-bottom: var(--spacing-xl);
}

/* media text component specific style for the cta */
.media-text-block__container .cmp-videoembed {
  margin-bottom: 0;
}

.cmp-videoembed .absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.cmp-videoembed__poster {
  width: 100%;
}

.cmp-videoembed__poster-overlay {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 50%) 18%, rgb(0 0 0 / 40%) 36%, rgb(0 0 0 / 20%) 100%);
  background-size: cover;
  background-position: center;
}

.cmp-videoembed__frame {
  width: 100%;
  height: 100%;
}

.cmp-videoembed__action-link.cmp-button {
  @include play-video-button;
}

.cmp-videoembed__action-link.cmp-button .cmp-button__icon {
  margin-top: 0;

  &::before {
    padding-left: var(--spacing-xxs);
  }
}

.cmp-videoembed--active .cmp-videoembed__action-link,
.cmp-videoembed--active .cmp-videoembed__poster,
.cmp-videoembed--active .cmp-videoembed__poster-overlay {
  display: none;
}

.cmp-videoembed:hover .cmp-videoembed__action-link {
  background-color: var(--color-white);
  color: var(--color-primary-500);
}

.cmp-videoembed__action-link .cmp-button__text {
  margin-right: var(--spacing-sm);
  white-space: pre;
}

.media-text-blocks__container .videoembed {
  flex-basis: 100%;
}

$scroll-indicator-size: 3.375rem;
$scroll-indicator-thickness: calc($scroll-indicator-size / 18);
$scroll-indicator-color: var(--color-white);

.homepage-stage-root {
  display: flex;
  position: relative;
  height: 100%;
  max-width: 100%;
  max-height: 67.5rem;
  height: calc(100vh - 6rem);
  min-height: 30rem;
  overflow: hidden;
  width: 100vw;
  margin: 0 auto;

  @media (--breakpoint-down-sm) {
    height: calc(100vh - 4.875rem);
  }

  .homepage-stage-wrapper {
    display: flex;
    width: 100%;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: linear-gradient(180deg, rgb(0 0 0 / 0.01%) 0%, rgb(0 0 0 / 0%) 0.01%, rgb(0 0 0 / 75%) 100%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .cmp-cta__image {
      &::after {
        content: none;
      }
    }

    .image-wrapper,
    .image-wrapper .cmp-responsive-image {
      height: 100%;
      width: 100%;
    }

    .homepage-stage-content {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      text-align: center;
      color: var(--color-white);
      z-index: 2;
      max-width: var(--page-max-width);

      @media #{$mq-5}, #{$mq-6} {
        max-width: var(--page-max-width);
      }

      .homepage-stage-title {
        font-size: 5rem;
        font-weight: bold;
        line-height: toRem(100px);
        margin-bottom: var(--spacing-md);

        @media (max-height: 768px) and (min-width: 960px) {
          line-height: 130%;
          font-size: 8vh;
        }
      }

      .homepage-stage-description {
        font-weight: normal;
      }

      @media (--breakpoint-down-md) {
        padding: 50% 5%;

        .homepage-stage-title {
          font-size: var(--font-size-h1);
          line-height: 3.5rem;
        }
      }
    }
  }

  .homepage-stage-video-root {
    position: absolute;
    width: 100%;
    height: 100%;

    video {
      object-fit: cover;
      height: 100%;
    }
  }

  .cmp-pagestage__dynamicmediaplayer {
    max-height: 100%;
  }
}

/* Overrides for Homepage max-width on content */
/* stylelint-disable-next-line selector-no-qualifying-type */
.homepage-v2 main.container div.cmp-container:first-child {
  max-width: toRem(1920px);
  margin: 0 auto;
}

.scroll-indicator {
  position: absolute;
  bottom: 3%;
  height: $scroll-indicator-size;
  width: $scroll-indicator-size;
  align-self: center;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 20%;
    right: 20%;
    bottom: 0;
    border: $scroll-indicator-color solid $scroll-indicator-thickness;
    border-radius: $scroll-indicator-size;
  }

  &::after {
    content: '';
    position: absolute;
    top: 26%;
    left: calc(50% - #{$scroll-indicator-thickness});
    border: $scroll-indicator-color solid $scroll-indicator-thickness;
    border-radius: 50%;
    animation-name: scroll-indicator-animation;
    animation-duration: 1.25s;
    animation-iteration-count: infinite;
    transform: translateY(0);
  }
}

@import '@react/common.scss';

.cmp-agenda-slider {
  @media only screen and (--breakpoint-down-md) {
    &__item--is-hidden {
      visibility: hidden;
      transition: visibility 0.3s;
    }
  }

  &__pagination {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;

    &-placeholder {
      padding: 0;

      li {
        margin: 0 var(--spacing-xs);
      }
    }

    &-page {
      cursor: pointer;
      margin: 0;
      width: var(--spacing-xs);
      height: var(--spacing-xs);
      border-radius: 50%;
      background: var(--color-gray-300);

      &:hover {
        background-color: var(--color-primary-500);
      }

      &--is-active {
        background-color: var(--color-black);
      }
    }
  }

  &__arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: var(--spacing-lg);
  }

  &__arrow {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: var(--spacing-xl);
    width: var(--spacing-xl);
    background-color: var(--color-gray-100);
    color: var(--color-gray-900);
    border-radius: var(--border-radius-m);
    padding: var(--spacing-xxs);
    border: var(--border-xs);

    &::after {
      font-family: var(--icon-font);

      @include regular-3xl;
    }

    &:hover,
    &:focus {
      background-color: var(--color-primary-500);
      color: var(--color-white);
    }

    &:focus {
      outline: var(--border-m) solid var(--color-focus-500);
    }

    &--is-previous {
      margin-right: var(--spacing-sm);

      &::after {
        content: var(--icon-keyboard_arrow_left);
      }
    }

    &--is-next {
      margin-left: var(--spacing-sm);

      &::after {
        content: var(--icon-keyboard_arrow_right);
      }
    }
  }
}

@media (hover: none) {
  .cmp-agenda-slider__arrow:hover {
    background-color: var(--color-primary-500);
    color: var(--color-white);
  }

  .cmp-agenda-slider__pagination-page.cmp-agenda-slider__pagination-page--is-active {
    background-color: var(--color-black);
  }
}

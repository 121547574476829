.cmp-teasercontainer--pictogram {
  .cmp-teaser {
    justify-content: center;
    z-index: 1;
    background: var(--color-gray-500);

    &:hover {
      background: var(--color-primary-500);
      box-shadow: none;
      transform: scale(1.05);
      z-index: 2;

      .cmp-teaser__image {
        visibility: hidden;
      }

      .cmp-teaser__image + .cmp-teaser__content {
        background: var(--color-primary-500);
      }

      .cmp-teaser__title,
      .cmp-teaser__title-text {
        color: var(--color-white);
      }
    }

    .cmp-teaser__image + .cmp-teaser__content {
      position: absolute;
      background: linear-gradient(135deg, rgb(0 0 0 / 7.5%) 0%, rgb(0 0 0 / 32.5%) 98.23%);
    }
  }

  .cmp-teaser__image {
    img {
      border-radius: var(--border-radius-m);
    }

    .cmp-image::before {
      padding-top: 100%;
    }
  }

  .cmp-teaser__content {
    color: var(--color-white);
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: unset;
    text-align: center;
    border-radius: var(--border-radius-m);
  }

  &.cmp-teasercontainer--image-hidden {
    .cmp-teaser__content {
      flex-direction: column;
    }

    .cmp-teaser .cmp-teaser__image + .cmp-teaser__content {
      position: relative;
    }
  }

  .cmp-teaser__title {
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    display: block;

    &-text {
      color: var(--color-white);
    }
  }

  .cmp-teaser__title-icon {
    padding-right: 0;
  }

  .cmp-button__icon {
    display: block;
    margin: 0 0 var(--spacing-sm);
    text-align: center;
    height: var(--spacing-xxl);
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight);

    &::before {
      top: var(--spacing-md);
      position: relative;
    }
  }

  .cmp-teaser__tag,
  .cmp-teaser__description,
  .cmp-teaser__action-container {
    display: none;
  }
}

.cmp-layoutcontainer--background-gray.cmp-layoutcontainer .cmp-teasercontainer--pictogram {
  .cmp-teaser {
    background: var(--color-gray-500);

    &:hover {
      background: var(--color-primary-500);
    }
  }
}

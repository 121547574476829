@import '@site/styles/vendor/splide/splide-core.min.css';
@import '@site/styles/foundations/typography.scss';

.cmp-media-gallery--background-grey {
  margin-bottom: var(--spacing-4xl);
  padding-top: var(--spacing-xl);
  position: relative;
}

.cmp-media-gallery--background-grey::before {
  content: '';
  position: absolute;
  display: block;
  z-index: -1;
  height: 100%;
  margin-top: calc(-1 * var(--spacing-xl));
  background: var(--color-gray-50);
  left: -6.75rem;
  width: calc(100% + (6.75rem * 2));
}

@media (--breakpoint-down-xl) {
  .cmp-media-gallery--background-grey::before {
    left: -1.75rem;
    width: calc(100% + (1.75rem * 2));
  }
}

@media (--breakpoint-down-lg) {
  .cmp-media-gallery--background-grey::before {
    left: -1.125rem;
    width: calc(100% + (1.125rem * 2));
  }
}

@media (--breakpoint-down-sm) {
  .cmp-media-gallery--background-grey::before {
    left: calc(-1 * (var(--spacing-lg) - var(--spacing-sm)));
    width: calc(100% + ((var(--spacing-lg) - var(--spacing-sm)) * 2));
  }
}

/* show all items in the editor mode */
.aem-AuthorLayer-Edit .swiper-wrapper {
  display: block;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
  padding: 1rem 0;
}

.splide__slide .slide__content {
  width: 100%;
}

.js-cmp-slider--events-carousel .splide__track {
  padding: 2rem 0;
}

.splide__arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: var(--spacing-lg);
}

.splide__arrows--hidden {
  display: none;
}

.splide__pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  margin: 0;

  li {
    margin: 0 var(--spacing-xs);
    display: flex;
  }
}

.splide__pagination__page {
  cursor: pointer;
  margin: 0;
  width: var(--spacing-xs);
  height: var(--spacing-xs);
  border-radius: 50%;
  background-color: var(--color-gray-300);
}

.splide__pagination__page:hover {
  background-color: var(--color-primary-500);
}

.splide__pagination__page.is-active,
.splide__pagination__page.is-paginationactive {
  background-color: var(--color-black);
}

.splide__pagination-current-item {
  @include regular-md;
}

.splide__sr {
  display: none;
}

.splide__arrow {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: var(--spacing-xl);
  width: var(--spacing-xl);
  background-color: var(--color-gray-100);
  color: var(--color-gray-900);
  border-radius: var(--border-radius-m);
  padding: var(--spacing-xxs);
  border: var(--border-xs);
  min-width: unset;
}

.splide__arrow.cmp-button {
  color: var(--color-gray-900);
}

.splide__arrow::after {
  font-family: var(--icon-font);
  font-size: var(--spacing-lg);
}

.splide__arrow:has(.splide__arrow--next) {
  margin-left: var(--spacing-md);
}

.splide__arrow:has(.splide__arrow--prev) {
  margin-right: var(--spacing-md);
}

.splide__arrow:hover,
.splide__arrow:focus {
  background-color: var(--color-primary-500);
  color: var(--color-white);
}

.splide__arrow:focus {
  outline: var(--border-m) solid var(--color-focus-500);
}

.splide__arrow .splide__arrow--next {
  margin-top: 0;
}

.splide__arrow .splide__arrow--prev {
  margin-top: 0;
}

@media (hover: none) {
  .splide__arrow:hover {
    background-color: var(--color-primary-500);
  }
}

.splide__pagination-placeholder {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:has(.splide__pagination-current-item) {
    width: var(--spacing-xl);
    height: var(--spacing-xl);
  }
}

/*
* Style policies / Slider types
*/
/* Cover Flow */
.js-cmp-slider--coverflow .splide__track {
  padding: 5% 0;
}

@media (--breakpoint-down-sm) {
  .js-cmp-slider--coverflow .splide__track,
  .js-cmp-slider--events-carousel .splide__track {
    padding: 15% 0;
  }

  .media-gallery .splide__pagination-placeholder {
    display: none;
  }
}

.js-cmp-slider--coverflow .splide__slide .slide__content,
.js-cmp-slider--events-carousel .splide__slide .slide__content {
  transform: scale(1, 1);
  transition: scale 0.5s;
}

.js-cmp-slider--coverflow .splide__slide.is-active .slide__content,
.js-cmp-slider--events-carousel .splide__slide.is-active .slide__content {
  transform: scale(1.2, 1.2);
  transition: scale 0.5s;
}

/* image/embed aspect ratio 16:9 */
.cmp-slider--ratio\:16\/9 .cmp-embed,
.cmp-slider--ratio\:16\/9 .cmp-image,
.cmp-slider--gallery .cmp-embed,
.cmp-slider--gallery .cmp-image,
.cmp-slider--carousel .cmp-embed,
.cmp-slider--carousel .cmp-image {
  position: relative;
}

.cmp-slider--ratio\:16\/9 .cmp-embed::before,
.cmp-slider--ratio\:16\/9 .cmp-image::before,
.cmp-slider--gallery .cmp-embed::before,
.cmp-slider--gallery .cmp-image::before,
.cmp-slider--carousel .cmp-embed::before,
.cmp-slider--carousel .cmp-image::before {
  content: '';
  display: block;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.cmp-slider--ratio\:16\/9 .cmp-embed iframe,
.cmp-slider--ratio\:16\/9 .cmp-image__image,
.cmp-slider--gallery .cmp-image__image,
.cmp-slider--gallery .cmp-embed iframe,
.cmp-slider--carousel .cmp-embed iframe,
.cmp-slider--carousel .cmp-image__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: none;
  object-fit: cover;
  object-position: center;
}

/*
* Multiple items
*/
.cmp-slider--multiple .splide {
  margin: 0 calc(var(--spacing-sm) * -1);
}

.cmp-slider--multiple .splide__slide {
  padding: 0 var(--spacing-sm);
}

/* 4 items in row */
.cmp-slider--multiple-4 .splide__slide {
  width: 25%;
}

@media (--breakpoint-down-md) {
  .cmp-slider--multiple-4 .splide__slide {
    width: 33.333%;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-slider--multiple-4 .splide__slide {
    width: 90%;
  }
}

/* 3 items in a row */
.cmp-slider--multiple-3 .splide__slide {
  width: 33.333%;
}

/* media gallery */
.cmp-media-gallery .splide__slide {
  width: 100%;
}

.cmp-media-gallery .splide__slide .image {
  position: relative;
}

.cmp-media-gallery .splide__slide .image .cmp-media-gallery__fullscreen-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: calc(var(--spacing-xxs) * 1.5);
  background: var(--color-white);
  color: var(--color-gray-500);
  font-size: 36px;
  text-align: center;
  z-index: 2;
  pointer-events: initial;
}

.cmp-media-gallery .splide__slide .image .cmp-media-gallery__fullscreen-btn:hover {
  color: var(--color-primary-500);
  cursor: pointer;
}

.cmp-media-gallery .splide__slide .cmp-media-gallery__description {
  padding: var(--spacing-xs);
  background: var(--color-white);
  border: var(--spacing-xxxs) solid var(--color-gray-50);
}

@media (--breakpoint-down-md) {
  .cmp-slider--multiple-3 .splide__slide {
    width: 50%;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-slider--multiple-3 .splide__slide {
    width: 90%;
  }

  .cmp-media-gallery.cmp-slider--multiple-3 .swiper-slide,
  .cmp-media-gallery.cmp-slider--multiple-4 .swiper-slide,
  .cmp-media-gallery.cmp-slider--multiple-6 .swiper-slide {
    grid-template-columns: 1fr 1fr;
  }
}

body:has(.cmp-header--intranet) .stage-hero-slider {
  @include extendBeyondContentWidth();
}

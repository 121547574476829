/*
* AEM Component:
* Layout Container
* follows the aem grid styles
*/

/* stylelint-disable selector-no-qualifying-type */

@include remove-grid-content('.container');

/* style policy - gray background */
.cmp-layoutcontainer--background-gray.cmp-layoutcontainer {
  margin-top: var(--spacing-xxl);
  position: relative;

  .cmp-teaser,
  .cmp-filterable-list__card {
    background-color: var(--color-white);
  }
}

.cmp-layoutcontainer--background-gray.cmp-layoutcontainer:not(:last-child) {
  margin-bottom: var(--spacing-4xl);
}

.cmp-layoutcontainer--background-gray::before {
  background: var(--color-gray-50);
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  height: calc(100% + var(--spacing-xxl));
  top: calc(-1 * var(--spacing-xxl));
  left: 50%;
  margin-left: -50vw;
  width: 100vw;
}

.cmp-layoutcontainer .aem-GridColumn:not(.teams-notifications) {
  min-height: var(--spacing-xxxs);
}

main.container {
  div.cmp-container:first-child {
    max-width: var(--page-max-width);
    margin: 0 auto;
  }
}

@media (--breakpoint-up-xxl) {
  main.container {
    margin: 0 auto;
    padding: 0 var(--grid-margin);
  }

  .cmp-layoutcontainer--background-gray::before {
    @include fullWidthBackgroundColor($position: absolute);
  }
}

/* disable resizing in editor - @todo add to layout view in aem somehow??? */
.is-selected.is-resizable[title='Layout container'] .editor-ResponsiveGrid-overlay-resizeHandle {
  display: none;
}

.cmp-layoutcontainer:has(.cmp-container.cmp-container--bg-gray) {
  background-color: var(--color-gray-50);

  .cmp-title-v2 {
    padding-top: var(--spacing-sm);
  }
}
